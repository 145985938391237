$(document).ready(function() {
    var swiper = new Swiper('.swiper-container', {
        slidesPerView: 1,
        spaceBetween: 10,
        autoplay: {
            delay: 4000,
        },
        // Responsive
        breakpoints: {
            480: {
                slidesPerView: 2,
                spaceBetween: 15
            },
            576: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 30
            },

        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        // direction: getDirection(),
        // on: {
        //     resize: function() {
        //         swiper.changeDirection(getDirection());
        //     }
        // },
    });
    var swiper = new Swiper('.list-mentor', {
        slidesPerView: 1,
        spaceBetween: 10,
        autoplay: {
            delay: 3000,
        },
        // Responsive
        breakpoints: {
            480: {
                slidesPerView: 2,
                spaceBetween: 15
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 30
            },

        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

    });

    // function getDirection() {
    //     var windowWidth = window.innerWidth;
    //     var direction = window.innerWidth <= 760 ? 'vertical' : 'horizontal';

    //     return direction;
    // }
    var list = new Swiper('.list-why', {
        slidesPerView: 1,
        autoplay: {
            delay: 5000,
        },
        spaceBetween: 10,
        // direction: getDirection(),
        // Responsive
        breakpoints: {
            480: {
                slidesPerView: 1,
                spaceBetween: 15
            },
            576: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 30
            },

        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

    });

    var list = new Swiper('.list-cus-say', {
        slidesPerView: 1,
        autoplay: {
            delay: 10000,
        },
        spaceBetween: 10,
        // direction: getDirection(),
        // Responsive
        breakpoints: {
            480: {
                slidesPerView: 1,
                spaceBetween: 15
            },
            576: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 30
            },

        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        // on: {
        //     resize: function() {
        //         list.changeDirection(getDirection());
        //     }
        // }
    });



});
